import { render, staticRenderFns } from "./ModalRemoveContactConfirm.vue?vue&type=template&id=f4c63dac&scoped=true&"
import script from "./ModalRemoveContactConfirm.vue?vue&type=script&lang=js&"
export * from "./ModalRemoveContactConfirm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4c63dac",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/bamboo/local-working-dir/WWW-WEBQA-JOB1/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f4c63dac')) {
      api.createRecord('f4c63dac', component.options)
    } else {
      api.reload('f4c63dac', component.options)
    }
    module.hot.accept("./ModalRemoveContactConfirm.vue?vue&type=template&id=f4c63dac&scoped=true&", function () {
      api.rerender('f4c63dac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/src/components/myaccount/notifications/ModalRemoveContactConfirm.vue"
export default component.exports